@import '../../../../scss/index.scss';
.biller-btn {
    background-color: $background-blue-dark;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    transition: 0.3s;
    font-size: 16px;
    color: white;

    &:hover {
        background-color: lighten($color: $background-blue-dark, $amount: 10);
        color: white;
        border-color: $background-blue-dark;
    }

    &:focus {
        background-color: lighten($color: $background-blue-dark, $amount: 10);
        color: white;
        border-color: $background-blue-dark;
    }
}

.payment-btn__add {
    background-color: $background-green;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    transition: 0.3s;
    font-size: 16px;
    color: white;

    &:hover {
        background-color: lighten($color: $background-green, $amount: 10);
        color: white;
        border-color: $background-green;
    }

    &:focus {
        background-color: $background-green;
        color: white;
        border-color: $background-green;
    }
}

@import '../../../scss/index.scss';

.table-home {
    padding: 0 20px;

    &__title {
        h3 {
            color: $font-grey;
            font-family: $font-roboto;
            font-weight: 500;
            font-size: 20px;
        }
    }
}
.ant-table-cell {
    padding: 4px !important;
}
// @import '../../../scss/index.scss';

#circulo {
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #5cb85c;
    margin-right: 5px;
    margin-top: 5px;
}
#circulo-false {
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #E54E58;
    margin-right: 5px;
    margin-top: 5px;
}
#circulo-yellow {
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #e5bd4e;
    margin-right: 5px;
    margin-top: 5px;
}
.btn-enviar {
    background-color: #68D78A;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    transition: 0.3s;
    font-size: 16px;
    color: white;
    // margin-left: 20px;

    &:hover {
        background-color: #6ce691;
        color: white;
        border-color: #68D78A;
    }
    &:focus {
        background-color: #6ce691;
        color: white;
        border-color: #68D78A;
    }
}
.btn-cancelar {
    background-color: #E54E58;
    border-radius: 4px;
    width: 200px;
    height: 40px;
    transition: 0.3s;
    font-size: 16px;
    color: white;
    margin-left: 20px;

    &:hover {
        background-color: #f75c66;
        color: white;
        border-color: #E54E58;
    }
}